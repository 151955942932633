<template>
    <div class="factorytemplate">
        <myhead :param="param" />
        <section>
            <div class="bannerfactory">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

            </div>
        </section>
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt18">能源产品应用方案</div>
            <div class="txt60" style="margin-top: 30px">光伏钢构厂房</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                辉腾能源屋顶光伏解决方案不仅满足常规建筑物防渗漏、抗沉降、防拉缩等各项设计要求，还具备高风速、高雪荷载、通风采光好、防热防火优、防震防水强，后期运维易等众多有点，可在保护整个建筑安全可靠的前提下，为客户带来更丰厚的回报。辉腾能源屋顶光伏解决方案重点在前期勘查，根据勘查结果进行可行性分析和方案设计。
            </div>
        </section>
        <section class="body1200" style="margin-top: 150px;">
            <div  class="txt42">
                方案概述
            </div>

            <div class="txt18" style="margin-top: 30px;width:860px;">

                随着光伏技术越来越成熟，已经国家政策的推动，投资工业厂房屋顶光伏备受青睐。辉腾能源屋顶光伏解决方案不仅满足常规建筑物防渗漏、抗沉降、防拉缩等各项设计要求，还具备高风速、高雪荷载、通风采光好、防热防火优、防震防水强，后期运维易等众多有点，在保护整个建筑安全可靠的前提下，为客户带来更丰厚的回报。辉腾能源屋顶光伏解决方案重点在前期勘查，根据勘查结果进行可行性分析和方案设计。为客户提供全程无忧的分布式光伏发电一站式服务。

            </div>

            <div class="factory_gs" style="margin-top: 60px;">
            </div>
        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div  class="txt42">
                项目规划
            </div>

            <div class="txt18" style="margin-top: 30px;width:860px;">
                分布式屋顶光伏勘察属于项目的前期工作，通过实地勘察，搜集屋顶相关资料，为后续方案设计和投资收益分析做准备。辉腾能源通过大量项目实施过程中经验积累，在工业厂房屋顶发电项目可行性分析及方案设计中，对实际的屋顶结构形式多样、装机容量、投资收益、合作模式等相关问题做分析。
            </div>
            <div class="htable" style="margin-top: 30px;justify-content: space-between;width: 860px;border-bottom: 1px solid #e7e7e7;">
                <div class="hcell" style="width:50px;"> </div>
                <div class="hcell ghm">
                    <span v-if="factorygh==1" class="txtH16 btLine">资源情况分析</span>
                    <span v-else class="txtH16 ghblockclick" @click="factorygh=1">资源情况分析</span>
                </div>
                <div class="hcell ghm">
                    <span v-if="factorygh==2" class="txtH16 btLine">光伏扶持政策</span>
                    <span v-else class="txtH16 ghblockclick" @click="factorygh=2">光伏扶持政策</span>
                </div>
                <div class="hcell ghm">
                    <span v-if="factorygh==3" class="txtH16 btLine">建筑屋顶分析</span>
                    <span v-else class="txtH16 ghblockclick" @click="factorygh=3">建筑屋顶分析</span>
                </div>
                <div class="hcell ghm">
                    <span v-if="factorygh==4" class="txtH16 btLine">建筑屋面荷载</span>
                    <span v-else class="txtH16 ghblockclick" @click="factorygh=4">建筑屋面荷载</span>
                </div>
                <div class="hcell ghm">
                    <span v-if="factorygh==5" class="txtH16 btLine">屋顶支架型式</span>
                    <span v-else class="txtH16 ghblockclick" @click="factorygh=5">屋顶支架型式</span>
                </div>
                <div class="hcell" style="width:50px;"> </div>
            </div>
        </section>
        <section class="body1200 factory_ghblock" v-if="factorygh==1">
            <div  class="txt42">
                资源情况分析
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                选址应优先选择太阳辐照量大、阴雨天气少、污染程度小的地区。若企业靠近海边，需考虑防盐雾防腐蚀、抗台风措施；企业所在地区沙尘大则需考虑防风沙和增加清洗的措施；若在寒冷地区则需要考虑防冻和除雪措施。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="factory_gh factory_gh11"> </div></div>
                <div class="hcell"><div class="factory_gh factory_gh12"> </div></div>
                <div class="hcell"><div class="factory_gh factory_gh13"> </div></div>
            </div>
        </section>
        <section class="body1200 factory_ghblock" v-if="factorygh==2">
            <div  class="txt42">
                光伏扶持政策
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                各地为了推动分布式光伏发展，纷纷出台省级、市级甚至县级的扶持政策。项目开发人员在项目开发前期需提前需熟悉这些政策，作为目前还需政府补贴发展的光伏产业，补贴政策的好坏直接影响了分布式的收益，因此当地政策好的分布式项目宜优先开发。
            </div>
        </section>
        <section class="body1200 factory_ghblock" v-if="factorygh==3">
            <div  class="txt42">
                建筑屋顶分析
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                建筑产权归属、设计使用寿命、材质、面积和朝向等也直接影响了分布式光伏电站是否可行及安装量。建筑产权归企业业主或当地政府所有，则适宜开发；租赁的厂房不适宜。同时工业厂房屋顶一般是彩钢板，在项目开发时需了解屋顶能使用的年限，年限太短不适宜进行开发。单个企业屋顶面积或屋顶总面积最好不少于10000平方米的屋顶（约可安装1MW）。同时踏勘时需量出屋顶方位角、屋顶倾斜角度和周围遮挡物如女儿墙的高度，以便后期确定系统装机量和发电量。

            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="factory_gh factory_gh31"> </div></div>
                <div class="hcell"><div class="factory_gh factory_gh32"> </div></div>
                <div class="hcell"><div class="factory_gh factory_gh33"> </div></div>
            </div>
        </section>
        <section class="body1200 factory_ghblock" v-if="factorygh==4">
            <div class="txt42">
                建筑屋面荷载
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                屋面荷载分为恒荷载和可变荷载。
                <br/><br/>
                恒荷载是指结构自重及灰尘荷载等，光伏电站需要运营25年，其自重属于恒荷载。
                <br/><br/>
                通常钢结构厂房上装光伏系统每平米会增加15公斤的重量，砖混结构厂房的屋顶每平米会增加80公斤的重量。在项目考察时，需要着重查看建筑设计说明中恒荷载的设计值，并落实除屋面自重外，是否额外增加其他荷载，如管道、吊置设备、屋面附属物等，并落实恒荷载是否有裕量能够安装光伏电站。
                <br/><br/>
                可变荷载是考虑极限状况下暂时施加于屋面的荷载
                <br/><br/>
                分为风荷载、雪荷载、地震荷载、活荷载等，是不可以占用的。特殊情况下，活荷载可以作为分担光伏电站荷载的选项，但不可以占用过多，需要具体分析。
            </div>
        </section>
        <section class="body1200 factory_ghblock" v-if="factorygh==5">
            <div  class="txt42">
                屋顶支架型式
            </div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                建筑屋顶主要有彩钢瓦、陶瓷瓦、钢混等几种，彩钢瓦分为直立锁边型、咬口型型、卡扣型型、固定件连接型。前两种需要专用转接件，后两种需要打孔固定；陶瓷瓦屋面可以使用专用转接件，也可以不与屋面固定，利用自重和屋面坡度附着其上；钢混结构屋面需要制作支架基础，基础与屋面可以生根也可以不生根，关键考虑屋面防水、抗风载能力、屋面设计荷载等因素。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="factory_gh factory_gh51"> </div></div>
                <div class="hcell"><div class="factory_gh factory_gh52"> </div></div>
                <div class="hcell"><div class="factory_gh factory_gh53"> </div></div>
            </div>
        </section>


        <section class="body1200" style="margin-top: 90px;">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txt18" style="width: 860px;margin-top: 60px;">
                辉腾光伏能源通过将信息技术、互联网技术与光伏技术跨界融合，集成高效光伏发电，高压储能，实现可视化家庭能源管理，场景化、简单化一键用电，为客户带来更安全、多发电、好管理的美好生活体验。
            </div>
            <div style="margin-top: 56px;">
                <img :src="factory_ys">
            </div>
        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_al hec_al1"><div class="hec_albg1" ><div class="altxt1">
                    <div><img :src="address"></div>
                    <div>四川阿坝1MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al2"><div class="hec_albg2" ><div class="altxt2">
                    <div><img :src="address"></div>
                    <div>四川理塘6MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al3"> <div class="hec_albg3" ><div class="altxt3">
                    <div><img :src="address"></div>
                    <div>重庆巫溪整县扶贫光伏项目</div>
                </div> </div></div></div>
            </div>
        </section>

        <section style="height: 90px;"></section>
    </div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    const param = {
        pageindex: 1,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one
        },
        data() {
            return {
                param,
                factorygh:1,
                factory_ys: require('../assets/factory_ys.png'),
                address: require('../assets/address.png'),
            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
    .factorytemplate{
        color: #202020;
        text-align: left;

    }
    .ghm{
        width: 152px;
        text-align: center;
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }
    .bannerfactory{
        background-image: url('~assets/factory_banner.jpg');
        height: 600px;
    }


    .factory_gs{
        background-image: url('~assets/factory_gs.jpg');
        height: 594px;
    }

    .hec_al{
        width: 374px;
        height: 270px;
        border-radius: 10px;

    }

    .hec_al1{
        background-image: url('~assets/hec_al1.jpg');
    }
    .hec_al2{
        background-image: url('~assets/hec_al2.jpg');
    }
    .hec_al3{
        background-image: url('~assets/hec_al3.jpg');
    }
    .factory_ghblock{
        margin-top: 60px;
        /*height: 734px;*/
        /*border: 1px solid red;*/
    }
    .factory_gh{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .factory_gh11{
        background-image: url('~assets/factory_gh11.jpg');
    }
    .factory_gh12{
        background-image: url('~assets/factory_gh12.jpg');
    }
    .factory_gh13{
        background-image: url('~assets/factory_gh13.jpg');
    }

    .factory_gh31{
        background-image: url('~assets/factory_gh31.jpg');
    }
    .factory_gh32{
        background-image: url('~assets/factory_gh32.jpg');
    }
    .factory_gh33{
        background-image: url('~assets/factory_gh33.jpg');
    }

    .factory_gh51{
        background-image: url('~assets/factory_gh51.jpg');
    }
    .factory_gh52{
        background-image: url('~assets/factory_gh52.jpg');
    }
    .factory_gh53{
        background-image: url('~assets/factory_gh53.jpg');
    }

    .altxt1{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg1{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt2{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg2{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt3{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg3{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .hec_al1:hover  .hec_albg1 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al1:hover .altxt1{
        display: inline;
    }

    .hec_al2:hover  .hec_albg2 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al2:hover .altxt2{
        display: inline;
    }
    .hec_al3:hover  .hec_albg3 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al3:hover .altxt3{
        display: inline;
    }

    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }
    .ghblockclick{
        cursor:pointer;
    }

    .ghblockclick:hover{
        color: #d7000f;
    }
</style>
